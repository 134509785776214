import { createSlice } from '@reduxjs/toolkit';

import isEqual from 'fast-deep-equal';

import {
    getCenterList,
    getDeliveryPartnerList,
    getLoanProductList,
    getRegionList,
    getReportDateList,
    loginUser,
} from './thunks';

const initialState = {
    data: null,
    userId: null,
    logoutResponse: null,
    regionList: null,
    centerList: null,
    deliveryPartnerList: null,
    loanProductList: null,
    reportDateList: ['1992-09-30'],
};

export const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        cleardata(state) {
            state.data = null;
            state.userId = null;
            state.regionList = null;
            state.centerList = null;
            state.loanProductList = null;
            state.deliveryPartnerList = null;
            state.reportDateList = null;
        },
        storeValues(state, action) {
            state.userId = action?.payload?.employeeId;
        },
        setRegionData: (state, action) => {
            state.regionList = action?.payload;
        },
        setCenterData: (state, action) => {
            state.centerList = action?.payload;
        },
        setLoanProductData: (state, action) => {
            state.loanProductList = action?.payload;
        },
        setDeliveryPartnerData: (state, action) => {
            state.deliveryPartnerList = action?.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginUser.pending, (state) => {
                state.data = null;
            })
            .addCase(loginUser.fulfilled, (state, action) => {
                state.data = action.payload;
                // state.regionList =
                //     action.payload?.data?.data?.userInfo?.region || [];
                // state.centerList =
                //     action.payload?.data?.data?.userInfo?.centre || [];
                // state.deliveryPartnerList =
                //     action.payload?.data?.data?.userInfo?.deliveryPartner || [];
                // state.loanProductList =
                //     action.payload?.data?.data?.userInfo?.loanProduct || [];
                state.logoutResponse = null;
            })
            .addCase(loginUser.rejected, (state) => {
                state.data = null;
            })
            .addCase(getReportDateList.fulfilled, (state, action) => {
                if (!isEqual(state.reportDateList, action.payload))
                    state.reportDateList = action.payload;
            })
            .addCase(getRegionList.fulfilled, (state, action) => {
                if (!isEqual(state.regionList, action.payload))
                    state.regionList = action.payload;
            })
            .addCase(getCenterList.fulfilled, (state, action) => {
                if (!isEqual(state.centerList, action.payload))
                    state.centerList = action.payload;
            })
            .addCase(getLoanProductList.fulfilled, (state, action) => {
                state.loanProductList = action.payload;
            })
            .addCase(getDeliveryPartnerList.fulfilled, (state, action) => {
                state.deliveryPartnerList = action.payload;
            })
            .addCase('login/CLEAR_STATE', () => {
                return initialState;
            });
    },
});

// Action creators are generated for each case reducer function
export const {
    cleardata,
    storeValues,
    setRegionData,
    setCenterData,
    setDeliveryPartnerData,
    setLoanProductData,
} = loginSlice.actions;

export default loginSlice.reducer;
