import { createSelector } from 'reselect';

const login = (state) => state?.login;

export const selectLoginData = createSelector([login], (state) => state?.data);
export const selectupdatePasscodeResponse = createSelector(
    [login],
    (state) => state?.setPass
);
export const selectUserId = createSelector([login], (state) => state?.userId);
export const selectReportDateList = createSelector(
    [login],
    (state) => state?.reportDateList
);
export const selectUserDetails = createSelector([login], (state) => state);
export const selectUserInfo = createSelector(
    [login],
    (state) => state.data?.data?.userInfo
);
export const selectcenterList = createSelector(
    [login],
    (state) => state?.centerList
);
export const selectRegionList = createSelector(
    [login],
    (state) => state?.regionList
);
export const selectLoanProductList = createSelector(
    [login],
    (state) => state?.loanProductList
);
export const selectDeliveryPartnerList = createSelector(
    [login],
    (state) => state?.deliveryPartnerList
);
