import { createAsyncThunk } from '@reduxjs/toolkit';

import apiService from '../../../service/apiService';
import apiServiceUMS from '../../../service/apiServiceUMS';

export const loginUser = createAsyncThunk(
    'DATA_FETCH/LOGIN',
    async (values, { rejectWithValue }) => {
        const credData = {
            userId: values.employeeId,
            password: values.password,
        };
        try {
            const response = await apiServiceUMS.post('/auth/login', credData);
            window.sessionStorage.setItem('token', response?.data?.token);
            window.sessionStorage.setItem('role', response?.data?.userInfo?.role);
            window.sessionStorage.setItem(
                'firstName',
                response?.data?.userInfo?.first_name
            );
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getReportDateList = createAsyncThunk(
    'REPORT_DATE_LIST',
    async (_, { rejectWithValue }) => {
        try {
            var response = await apiService.get(`/api/v1/filters/reportingdate`);
            return response?.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getCenterList = createAsyncThunk(
    'CENTER_LIST',
    async (values, { rejectWithValue }) => {
        try {
            var response = await apiService.post(`/api/v1/filters/centres`, values);
            return response?.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getLoanProductList = createAsyncThunk(
    'LoanProduct_List',
    async (_, { rejectWithValue }) => {
        try {
            var response = await apiService.get(`/api/v1/filters/loanproduct`);
            return response?.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
export const getDeliveryPartnerList = createAsyncThunk(
    'DeliveryPartner_List',
    async (_, { rejectWithValue }) => {
        try {
            var response = await apiService.get(`/api/v1/filters/deliverypartner`);
            return response?.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getRegionList = createAsyncThunk(
    'REGION_LIST',
    async (_, { rejectWithValue }) => {
        try {
            var response = await apiService.get(`/api/v1/filters/region`);
            return response?.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
