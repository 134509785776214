import { createSlice } from '@reduxjs/toolkit';

import {
    deleteFiles,
    downloadDemandExcel,
    getImportedHistory,
    uploadFilesHistory,
} from './thunks';

const initialState = {
    data: null,
    reportingDate: null,
    deleteResponse: null,
    importedHistory: null,
    isRefreshHistory: false,
    sampleFile: null,
    currentPage: 1,
    totalResults: 0,
    limit: 10,
    skip: 0,
    rowsPerPage: [5, 10, 25, 50, 100],
};

export const uploadSlice = createSlice({
    name: 'upload',
    initialState,
    reducers: {
        pageChange(state, action) {
            state.currentPage = action.payload;
            state.skip = (action.payload - 1) * state.limit;
        },
        rowsPerPageChange(state, action) {
            state.skip = 0;
            state.currentPage = 1;
            state.limit = action.payload;
        },
        updateTotalResults(state, action) {
            state.totalResults = action.payload;
        },
        updateReportingdate(state, action) {
            state.reportingDate = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(uploadFilesHistory.fulfilled, (state, action) => {
                state.data = action.payload;
                state.isRefreshHistory = !state.isRefreshHistory;
            })
            .addCase(uploadFilesHistory.rejected, (state) => {
                state.data = null;
                state.isRefreshHistory = !state.isRefreshHistory;
            })
            .addCase(getImportedHistory.fulfilled, (state, action) => {
                state.importedHistory = action.payload?.data;
            })
            .addCase(getImportedHistory.rejected, (state) => {
                state.data = null;
            })
            .addCase(downloadDemandExcel.fulfilled, (state, action) => {
                state.sampleFile = action.payload;
            })
            .addCase(downloadDemandExcel.rejected, (state) => {
                state.data = null;
            })
            .addCase(deleteFiles.fulfilled, (state, action) => {
                state.deleteResponse = action.payload;
            })
            .addCase(deleteFiles.rejected, (state) => {
                state.data = null;
            })
            .addCase('upload/CLEAR_STATE', () => {
                return initialState;
            });
    },
});

// Action creators are generated for each case reducer function
export const {
    pageChange,
    rowsPerPageChange,
    updateTotalResults,
    listUpdated,
    updateReportingdate,
} = uploadSlice.actions;

export default uploadSlice.reducer;
