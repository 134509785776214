import { createAsyncThunk } from '@reduxjs/toolkit';

import apiService from '../../../service/apiService';

export const getMainGraphData = createAsyncThunk(
    'MAIN_GRAPH_DATA',
    async (values, { rejectWithValue }) => {
        try {
            const updatedValues = {
                ...values,
                displayUnit: values?.displayUnit || 'count',
            };
            var response = await apiService.post(
                `api/v1/smareport/maingraph`,
                updatedValues
            );
            return response?.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getSideGraphData = createAsyncThunk(
    'SIDE_GRAPH_DATA',
    async (values, { rejectWithValue }) => {
        try {
            const updatedValues = {
                ...values,
                displayUnit: values?.displayUnit || 'count',
            };
            var response = await apiService.post(
                `api/v1/smareport/sidegraph`,
                updatedValues
            );
            return response?.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getSMAReport = createAsyncThunk(
    'SMA_TABLE_VIEW',
    async (values, { rejectWithValue }) => {
        try {
            const updatedValues = {
                ...values,
                displayUnit: values?.displayUnit || 'count',
            };
            var response = await apiService.post(
                `/api/v1/smareport/tableview`,
                updatedValues
            );
            return response?.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getNpaMainGraphData = createAsyncThunk(
    'NPA_MAIN_GRAPH_DATA',
    async (values, { rejectWithValue }) => {
        try {
            const updatedValues = {
                ...values,
                displayUnit: values?.displayUnit || 'count',
            };
            var response = await apiService.post(
                `api/v1/npareport/maingraph`,
                updatedValues
            );
            return response?.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

//API to get data category wise(SMA0,SMA1,SMA2,SMA3)
export const getNPAReportRegularized = createAsyncThunk(
    'SMA_REPORT_REGULARIZED',
    async (values, { rejectWithValue }) => {
        try {
            const updatedValues = {
                ...values,
                displayUnit: values?.displayUnit || 'count',
            };
            var response = await apiService.post(
                `api/v1/smareport/smacategory`,
                updatedValues
            );
            return response?.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getNpaSideGraphData = createAsyncThunk(
    'NPA_SIDE_GRAPH_DATA',
    async (values, { rejectWithValue }) => {
        try {
            const updatedValues = {
                ...values,
                displayUnit: values?.displayUnit || 'count',
            };
            var response = await apiService.post(
                `api/v1/npareport/sidegraph`,
                updatedValues
            );
            return response?.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getNpaTableView = createAsyncThunk(
    'NPA_TABLE_VIEW',
    async (values, { rejectWithValue }) => {
        try {
            const updatedValues = {
                ...values,
                reportingSMA: 'SMA3',
            };
            if ('displayUnit' in updatedValues) {
                delete updatedValues.displayUnit;
            }

            var response = await apiService.post(
                `api/v1/npareport/tableview`,
                updatedValues
            );
            return response?.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getSmaReportList = createAsyncThunk(
    'Sma_Report',
    async (_, { rejectWithValue }) => {
        try {
            var response = await apiService.post(`/smareport/filter/sma`);
            return response?.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getODMainGraphData = createAsyncThunk(
    'OD_MAIN_GRAPH_DATA',
    async (values, { rejectWithValue }) => {
        try {
            const updatedValues = {
                ...values,
                displayUnit: values?.displayUnit || 'count',
            };
            var response = await apiService.post(
                `api/v1/odreport/maingraph`,
                updatedValues
            );
            return response?.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getODSideGraphData = createAsyncThunk(
    'OD_SIDE_GRAPH_DATA',
    async (values, { rejectWithValue }) => {
        try {
            const updatedValues = {
                ...values,
                displayUnit: values?.displayUnit || 'count',
            };
            var response = await apiService.post(
                `/api/v1/odreport/sidegraph`,
                updatedValues
            );
            return response?.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getODTableView = createAsyncThunk(
    'OD_TABLE_VIEW',
    async (values, { rejectWithValue }) => {
        try {
            const updatedValues = {
                ...values,
                reportingSMA: 'SMA0',
            };

            var response = await apiService.post(
                `api/v1/odreport/tableview`,
                updatedValues
            );
            return response?.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getDECAYReport = createAsyncThunk(
    'DECAY_MAINGRAPH',
    async (values, { rejectWithValue }) => {
        try {
            const updatedValues = {
                ...values,
                displayUnit: values?.displayUnit || 'count',
            };
            var response = await apiService.post(
                `/api/v1/decayreport/maingraph`,
                updatedValues
            );
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const downloadCXReport = createAsyncThunk(
    'DOWNLOAD_CX_REPORT',
    async (values, { rejectWithValue }) => {
        try {
            var response = await apiService.post(`api/v1/download/sma`, values, {
                responseType: 'blob',
            });
            const href = URL.createObjectURL(response);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', `CX-Report.xlsx`);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const downloadNPACXReport = createAsyncThunk(
    'DOWNLOAD_CX_REPORT',
    async (values, { rejectWithValue }) => {
        try {
            var response = await apiService.post(`api/v1/download/npa`, values, {
                responseType: 'blob',
            });
            const href = URL.createObjectURL(response);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', `CX-Report.xlsx`);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getFOList = createAsyncThunk(
    'FO_LIST',
    async (values, { rejectWithValue }) => {
        try {
            var response = await apiService.post(`api/v1/filters/foid`, values);
            return response?.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getComarisonDates = createAsyncThunk(
    'REPORTS_COMPARISON_DATES',
    async (value, { rejectWithValue }) => {
        try {
            var response = await apiService.get(
                `api/v1/uploaddata/getcomparisondate?reportingDate=${value}`
            );
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getDecayReportDetailInfo = createAsyncThunk(
    'DECAY_REPORT_DETAIL_INFO',
    async (values, { rejectWithValue }) => {
        try {
            var response = await apiService.post(
                `api/v1/decayreport/decayreportdetailedinfo`,
                values
            );
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getDecayReportDetailedDownload = createAsyncThunk(
    'DECAY_REPORT_DETAIL_INFO',
    async (values, { rejectWithValue }) => {
        try {
            var response = await apiService.post(
                `api/v1/decayreport/detailed-download`,
                values,
                {
                    responseType: 'blob',
                }
            );
            const href = URL.createObjectURL(response);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', `Detailed-Decay-Report.xlsx`);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
