import { createSlice } from '@reduxjs/toolkit';

import {
    getDecayReportDetailInfo,
    getFOList,
    getMainGraphData,
    getNpaMainGraphData,
    getNPAReportRegularized,
    getNpaSideGraphData,
    getNpaTableView,
    getODMainGraphData,
    getODSideGraphData,
    getODTableView,
    getSideGraphData,
    getSMAReport,
    getSmaReportList,
} from './thunks';

const initialState = {
    data: null,
    drawerOpen: false,
    smaReports: {},
    smaReportsList: [],
    mainGraphData: null,
    sideGraphData: null,
    selectedTabValue: 'SMA',
    foList: null,
    dpd_1_30: [],
    dpd_31_60: [],
    dpd_61_90: [],
    decayReportInfo: null,
};

export const reportSlice = createSlice({
    name: 'reports',
    initialState,
    reducers: {
        openDrawer: (state) => {
            state.drawerOpen = true;
        },
        closeDrawer: (state) => {
            state.drawerOpen = false;
        },
        updateTabValue: (state, action) => {
            state.selectedTabValue = action.payload;
        },
        updateDpdValues: (state, action) => {
            switch (action.payload.dpdCategory) {
                case 'SMA0': {
                    state.dpd_1_30 = action.payload.dpdValues;
                    state.dpd_31_60 = [];
                    state.dpd_61_90 = [];
                    break;
                }
                case 'SMA1': {
                    state.dpd_31_60 = action.payload.dpdValues;
                    break;
                }
                case 'SMA2': {
                    state.dpd_61_90 = action.payload.dpdValues;
                    break;
                }
                default:
                    break;
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getSMAReport.fulfilled, (state, action) => {
                state.smaReports = action.payload;
            })
            .addCase(getNpaTableView.fulfilled, (state, action) => {
                state.smaReports = action.payload;
            })
            .addCase(getODTableView.fulfilled, (state, action) => {
                state.smaReports = action.payload;
            })
            .addCase(getSmaReportList.fulfilled, (state, action) => {
                state.smaReportsList = action.payload;
            })
            .addCase(getMainGraphData.rejected, (state) => {
                state.mainGraphData = null;
            })
            .addCase(getMainGraphData.fulfilled, (state, action) => {
                state.mainGraphData = action.payload;
            })
            .addCase(getODMainGraphData.rejected, (state) => {
                state.mainGraphData = null;
            })
            .addCase(getODMainGraphData.fulfilled, (state, action) => {
                state.mainGraphData = action.payload;
            })
            .addCase(getSideGraphData.rejected, (state) => {
                state.sideGraphData = null;
            })
            .addCase(getSideGraphData.fulfilled, (state, action) => {
                state.sideGraphData = action.payload;
            })
            .addCase(getNpaMainGraphData.rejected, (state) => {
                state.mainGraphData = null;
            })
            .addCase(getNpaMainGraphData.fulfilled, (state, action) => {
                state.mainGraphData = action.payload;
            })
            .addCase(getNPAReportRegularized.rejected, (state) => {
                state.mainGraphData = null;
            })
            .addCase(getNPAReportRegularized.fulfilled, (state, action) => {
                state.mainGraphData = action.payload;
            })
            .addCase(getNpaSideGraphData.rejected, (state) => {
                state.sideGraphData = null;
            })
            .addCase(getNpaSideGraphData.fulfilled, (state, action) => {
                state.sideGraphData = action.payload;
            })
            .addCase(getODSideGraphData.rejected, (state) => {
                state.sideGraphData = null;
            })
            .addCase(getODSideGraphData.fulfilled, (state, action) => {
                state.sideGraphData = action.payload;
            })
            .addCase(getFOList.rejected, (state) => {
                state.foList = null;
            })
            .addCase(getFOList.fulfilled, (state, action) => {
                state.foList = action.payload;
            })
            .addCase(getDecayReportDetailInfo.rejected, (state) => {
                state.decayReportInfo = null;
            })
            .addCase(getDecayReportDetailInfo.fulfilled, (state, action) => {
                state.decayReportInfo = action.payload;
            })
            .addCase('reports/CLEAR_STATE', () => {
                return initialState;
            });
    },
});

// Action creators are generated for each case reducer function
export const { openDrawer, closeDrawer, updateTabValue, updateDpdValues } =
    reportSlice.actions;

export default reportSlice.reducer;
