import { combineReducers, configureStore } from '@reduxjs/toolkit';

import {
    FLUSH,
    PAUSE,
    PERSIST,
    persistReducer,
    persistStore,
    PURGE,
    REGISTER,
    REHYDRATE,
} from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';

import loginReducer from '../features/login/redux/slice';
import reportsReducer from '../features/reports/redux/slice';
import uploadReducer from '../features/upload/redux/slice';
import userReducer from '../features/userCreation/redux/slice';
import commonReducer from '../redux/commonSlice';
import globalReducer from '../redux/slice';
import { loaderMiddleware, snackBarMiddleware } from '../service/apiMiddleware';
import clearAllReducersMiddleware from './clearAllReducersMiddleware';
const persistConfig = {
    key: 'root',
    version: 1,
    storage: storageSession,
    whitelist: ['login'],
};

const rootReducer = combineReducers({
    login: loginReducer,
    reports: reportsReducer,
    upload: uploadReducer,
    user: userReducer,
    global: globalReducer,
    common: commonReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Define a root reducer that handles the DESTROY_SESSION action
// const rootReducerWithSessionHandling = (state, action) => {
//     if (action.type === DESTROY_SESSION) {
//         // Clear the entire Redux store when DESTROY_SESSION is dispatched
//         return persistedReducer(undefined, action);
//         // state = undefined;
//     }
//     return persistedReducer(state, action);
// };

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        })
            .concat(clearAllReducersMiddleware)
            .concat(loaderMiddleware)
            .concat(snackBarMiddleware),
});

export let persistor = persistStore(store);
