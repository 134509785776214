import { createAsyncThunk } from '@reduxjs/toolkit';

import apiService from '../../../service/apiService';
// import AwsFileDownload from '../../../utils/AwsFileDownload';
import { buildDynamicURL } from '../../../utils/utils';

export const uploadFilesHistory = createAsyncThunk(
    'DATA_FETCH/UPLOAD_HISTORY',
    async ({ file, date }, { rejectWithValue }) => {
        try {
            var response = await apiService.post(
                `/api/v1/uploaddata/files?reportingDate=${date}`,
                file
            );
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getImportedHistory = createAsyncThunk(
    'DATA_FETCH/IMPORT_HISTORY',
    async (values, { rejectWithValue }) => {
        try {
            const url = buildDynamicURL('api/v1/uploaddata/importhistory', values, {
                skip: 0,
                limit: 10,
            });
            var response = await apiService.get(url, values);
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const downloadDemandExcel = createAsyncThunk(
    'download_Demand_Excel',
    async (value, { rejectWithValue }) => {
        try {
            var response = await apiService.get(
                `api/v1/uploaddata/download?etag=${value}`
            );
            if (response) {
                window.location.href = response?.data;
            }

            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const updateStatus = createAsyncThunk(
    'DATA_FETCH/UPDATE_STATUS',
    async (payload, { rejectWithValue }) => {
        try {
            var response = await apiService.post(
                `/api/v1/uploaddata/updatestatus`,
                payload
            );
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const uploadPreprocessing = createAsyncThunk(
    'DATA_FETCH/UPDATE_STATUS',
    async (payload, { rejectWithValue }) => {
        try {
            var response = await apiService.post(
                `/api/v1/uploaddata/preprocessing`,
                payload
            );
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const deleteFiles = createAsyncThunk(
    'DATA_FETCH/DELETE_FILES',
    async (payload, { rejectWithValue }) => {
        try {
            var response = await apiService.post(`/importdata/deletedata`, payload);
            return response?.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getComarisonDatesList = createAsyncThunk(
    'REPORTS_COMPARISON_DATES_List',
    async (value, { rejectWithValue }) => {
        try {
            var response = await apiService.get(
                `api/v1/uploaddata/getcomparisonlist?reportingDate=${value}`
            );
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
