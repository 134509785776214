export default function convertTextFormat(text, format) {
    if (!text) {
        return '';
    } else {
        if (format === 'CAMEL_CASE') {
            return text.replace(/[_-]\w/g, (match) => match.charAt(1).toUpperCase());
        } else if (format === 'SNAKE_CASE') {
            let snakeCaseString = text.replace(
                /[A-Z]/g,
                (match) => `_${match.toLowerCase()}`
            );
            if (snakeCaseString.startsWith('_')) {
                return snakeCaseString.slice(1);
            } else {
                return snakeCaseString;
            }
        } else if (format === 'PASCAL_CASE') {
            text = text.replace(/[_-]\w/g, (match) => match.charAt(1).toUpperCase());
            return text.charAt(0).toUpperCase() + text.slice(1);
        } else if (format === 'TITLE_CASE') {
            text = text.split(/\W+/);
            for (var i = 0; i < text.length; i++) {
                text[i] =
                    text[i].charAt(0).toUpperCase() + text[i].slice(1).toLowerCase();
            }
            text = text.join(' ');
            text = text.replace(/[_-]\w/g, (match) => match.charAt(1).toUpperCase());
            text = text.charAt(0).toLowerCase() + text.slice(1);
            text = text.replace(/[A-Z]/g, (match) => `_${match}`);
            const words = text.split('_');
            const titleCaseWords = words.map(
                (word) => word.charAt(0).toUpperCase() + word.slice(1)
            );
            let inputString = titleCaseWords.join(' ');
            let finalwords = inputString.split(/\W+/);
            for (var j = 0; j < finalwords.length; j++) {
                finalwords[j] =
                    finalwords[j].charAt(0).toUpperCase() +
                    finalwords[j].slice(1).toLowerCase();
            }
            return finalwords.join(' ');
        } else if (format === 'Captialized_Case') {
            let flatCaseText = text.toLowerCase();
            let firstChar = flatCaseText.charAt(0).toUpperCase();
            let transformedString = firstChar + flatCaseText.slice(1);
            return transformedString;
        } else {
            return text;
        }
    }
}
