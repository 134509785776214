import { createAsyncThunk } from '@reduxjs/toolkit';

import apiServiceUMS from '../../../service/apiServiceUMS';
import { buildDynamicURL } from '../../../utils/utils';

export const getUserList = createAsyncThunk(
    'USER/USER_LIST',
    async (values, { rejectWithValue }) => {
        try {
            const url = buildDynamicURL('/user/getusers', values, {
                skip: 0,
                limit: 10,
            });
            var response = await apiServiceUMS.get(url);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const changeUserStatus = createAsyncThunk(
    'USER/BLOCK_USER',
    async (values, { rejectWithValue }) => {
        try {
            var response = await apiServiceUMS.put(`/user/blockuser`, values);
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const resetPassword = createAsyncThunk(
    'USER/RESET_PASSWORD',
    async (values, { rejectWithValue }) => {
        try {
            var response = await apiServiceUMS.put(`/user/resetpassword`, values);
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getUserRoles = createAsyncThunk(
    'USER/USER_ROLES',
    async (_, { rejectWithValue }) => {
        try {
            var response = await apiServiceUMS.get(`/user/getrolelist`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const createNewUser = createAsyncThunk(
    'USER/CREATE_USER',
    async (values, { rejectWithValue }) => {
        try {
            var response = await apiServiceUMS.post('/user/create', values);
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const viewCurrentUser = createAsyncThunk(
    'USER/VIEW_USER',
    async (email, { rejectWithValue }) => {
        try {
            var response = await apiServiceUMS.get(`/user/viewuser?userId=${email}`);
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const updateUser = createAsyncThunk(
    'USER/UPDATE_USER',
    async (values, { rejectWithValue }) => {
        try {
            var response = await apiServiceUMS.put(`/user/edituser`, values);
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
